<template>
    <div class="checkbox-input">
        <label :for="name" class="checkbox-container">
            {{ label }}
            <input
                type="checkbox"
                :name="name"
                :id="name"
                :checked="checked"
                v-model="check"
                @change="$emit('change', check)"
            />
            <span class="checkmark"></span>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        label: { default: "label" },
        name: { default: "name" },
        checked: { type: Boolean }
    },
    data() {
        return {
            check: this.checked
        };
    }
};
</script>

<style lang="scss">
.checkbox-input {
    * {
        font-size: 18px;
        letter-spacing: 0.1em;
        font-family: "Titillium Web", sans-serif;
    }

    input {
        border: none;
        border-bottom: 3px solid $black;
        box-sizing: border-box;
        padding: 0.8rem 1rem;
        min-height: 3.5rem;
        transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        background-color: $washed-red;

        &::placeholder {
            color: $grey;
        }

        &:focus {
            outline: none;
            border-bottom: 3px solid $blue;
        }
    }

    .remark {
        margin: 0;
        color: $red;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}
</style>
