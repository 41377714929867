var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[(_vm.error)?_c('p',{staticClass:"invalid red"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"form-element"},[_c('Input',{class:{ invalid: _vm.errors.title },attrs:{"name":"title","label":_vm.$t('portfolio.add.title'),"placeholder":_vm.$t('portfolio.add.title-placeholder'),"required":true,"remark":_vm.errors.title ? { message: _vm.$t('error.title') } : null}})],1),_c('div',{staticClass:"form-element"},[_c('Input',{attrs:{"name":"designer","label":_vm.$t('portfolio.add.designer'),"placeholder":_vm.$t('portfolio.add.designer-placeholder')}})],1),_c('div',{staticClass:"form-element"},[_c('Input',{attrs:{"name":"renderer","label":_vm.$t('portfolio.add.renderer'),"placeholder":_vm.$t('portfolio.add.renderer-placeholder')}})],1),_c('div',{staticClass:"form-element"},[_c('Input',{class:{ invalid: _vm.errors.location },attrs:{"name":"location","label":_vm.$t('portfolio.add.location'),"placeholder":_vm.$t('portfolio.add.location-placeholder'),"required":true,"remark":_vm.errors.location
                        ? { message: _vm.$t('error.location') }
                        : null}})],1),_c('div',{staticClass:"form-element"},[_c('SelectInput',{class:{ invalid: _vm.errors.category },attrs:{"name":"category","label":_vm.$t('portfolio.add.category'),"required":true,"placeholder":_vm.$t('portfolio.add.category-placeholder'),"selected":_vm.category ? _vm.category : null,"remark":_vm.errors.category
                        ? { message: _vm.$t('error.category') }
                        : null,"data":{
                    interior: _vm.$t('portfolio.interior'),
                    exterior: _vm.$t('portfolio.exterior'),
                    floorplan: _vm.$t('portfolio.floorplan')
                }}})],1),_c('div',{staticClass:"form-element date-input"},[_c('DateInput',{class:{ invalid: _vm.errors.date },attrs:{"name":"date","label":_vm.$t('portfolio.add.date'),"placeholder":_vm.$t('portfolio.add.date-placeholder'),"required":true,"remark":_vm.errors.date ? { message: _vm.$t('error.date') } : null,"ongoing":true},on:{"date-selected":_vm.setDate,"ongoing-selected":_vm.setOngoing}})],1),_c('div',{staticClass:"form-element"},[_c('CheckboxInput',{attrs:{"name":"visible","label":_vm.$t('portfolio.add.visible'),"checked":true},on:{"change":_vm.setVisible}})],1),_c('div',{staticClass:"form-element"},[_c('FileInput',{class:{
                    invalid:
                        _vm.errors.file ||
                        _vm.errors.file_type ||
                        _vm.errors.file_size ||
                        _vm.errors.thumbnail
                },attrs:{"name":"images","label":_vm.$t('portfolio.add.file'),"placeholder":_vm.$t('portfolio.add.file-placeholder'),"required":true,"remark":_vm.errors.file
                        ? { message: _vm.$t('error.file') }
                        : _vm.errors.file_type
                        ? { message: _vm.$t('error.file-type') }
                        : _vm.errors.file_size
                        ? { message: _vm.$t('error.file-size') }
                        : _vm.errors.thumbnail
                        ? { message: _vm.$t('error.file-thumbnail') }
                        : null},on:{"changedInput":function($event){_vm.errors.file = false;
                    _vm.errors.file_type = false;
                    _vm.errors.file_size = false;},"errorType":function($event){_vm.errors.file_type = true},"errorSize":function($event){_vm.errors.file_size = true},"filesChanged":_vm.fileChanged,"thumbSelected":_vm.setThumbnail}}),_c('div',{attrs:{"id":"thumbnail-grid"}})],1),_c('div',{staticClass:"form-element hidden"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.thumbnail),expression:"thumbnail"}],attrs:{"type":"text","name":"thumbnail","id":"thumbnail"},domProps:{"value":(_vm.thumbnail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.thumbnail=$event.target.value}}})]),_c('div',{staticClass:"bottom right"},[_c('div',{staticClass:"container back-link"},[_c('Link',{attrs:{"back":true,"path":"/portfolio","title":_vm.$t('nav.return')}})],1),_c('button',{attrs:{"type":"submit"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("portfolio.add.submit"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }