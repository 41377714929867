<template>
    <div class="input">
        <label :for="name"
            >{{ label }} <span v-if="required" class="asterix">*</span></label
        >
        <select
            :id="name"
            :name="name"
            @change="$emit('change')"
        >
            <div class="caret"></div>
            <option value="" :selected="!data[selected]" disabled hidden>{{
                placeholder
            }}</option>
            <option
                v-for="(text, option) in data"
                :key="option"
                :value="option"
                :selected="option == selected.toLowerCase()"
            >
                {{ text }}
            </option>
        </select>
        <p v-if="remark" class="remark">{{ remark.message }}</p>
    </div>
</template>

<script>
export default {
    props: {
        label: { default: "label" },
        name: { default: "name" },
        placeholder: { default: "Placeholder" },
        remark: { default: null },
        required: { type: Boolean },
        selected: { type: String },
        data: { type: Object }
    },
};
</script>

<style lang="scss">
.input {
    width: 100%;
    display: flex;
    flex-direction: column;

    * {
        font-size: 18px;
        letter-spacing: 0.1em;
    }

    label {
        font-family: "Titillium Web", sans-serif;
        margin-bottom: 1rem;
    }

    select {
        border: none;
        border-bottom: 3px solid $black;
        box-sizing: border-box;
        padding: 0.8rem 1.5rem;
        appearance: none;
        padding-right: 2rem;
        min-height: 3.5rem;
        cursor: pointer;
        transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);

        background-image: url("../../assets/icons/caret-down.svg");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 80%;
        background-size: 1rem 3rem;
        background-color: $washed-red;

        &:invalid {
            color: $grey;
        }

        option {
            color: $black;
        }

        &:focus {
            outline: none;
            border-bottom: 3px solid $blue;
        }
    }

    .remark {
        margin: 0;
        color: $red;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}
</style>
