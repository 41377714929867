<template>
    <div class="date-input">
        <div class="calendar-group">
            <label :for="name">
                {{ label }} <span v-if="required" class="asterix">*</span>
            </label>
            <datepicker
                :language="lang[this.$root.$i18n.locale]"
                :id="name"
                :name="name"
                format="dd / MM / yyyy"
                :placeholder="placeholder"
                v-model="date"
                @input="$emit('date-selected', date)"
            ></datepicker>
            <p v-if="remark" class="remark">{{ remark.message }}</p>
        </div>
        <div v-if="ongoing" class="ongoing-group">
            <label for="ongoing" class="checkbox-container">
                {{ $t("portfolio.add.ongoing") }}
                <input
                    type="checkbox"
                    name="ongoing"
                    id="ongoing"
                    @change="$emit('ongoing-selected', check)"
                    v-model="check"
                />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { en, nl } from "vuejs-datepicker/src/locale";
export default {
    components: {
        Datepicker
    },
    data() {
        return {
            date: this.value || null,
            check: this.checked || false,
            lang: {
                en: en,
                nl: nl
            }
        };
    },
    props: {
        label: { default: "label" },
        name: { default: "name" },
        type: { default: "text" },
        placeholder: { default: "Placeholder" },
        remark: { default: null },
        required: { type: Boolean },
        ongoing: { type: Boolean },
        value: { default: null },
        checked: { type: Boolean }
    }
};
</script>

<style lang="scss">
.date-input {
    width: 100%;
    display: flex;
    flex-direction: column;

    .calendar-group {
        max-width: 50%;
        margin-bottom: 1rem;

        input {
            margin-top: 1rem;
            border: none;
            border-bottom: 3px solid $black;
            box-sizing: border-box;
            padding: 0.8rem 1rem;
            min-height: 3.5rem;
            min-width: 100%;
            transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            background-color: $washed-red;
            cursor: pointer;

            background-image: url("../../assets/icons/calendar.svg");
            background-repeat: no-repeat;
            background-position-x: 97%;
            background-position-y: 80%;
            background-size: 2rem 3rem;
            background-color: $washed-red;

            &::placeholder {
                color: $grey;
            }

            &:focus {
                outline: none;
                border-bottom: 3px solid $blue;
            }
        }
    }

    @media (max-width: 700px) {
        .calendar-group {
            max-width: 100%;
        }
    }

    * {
        font-size: 18px;
        letter-spacing: 0.1em;
        font-family: "Titillium Web", sans-serif;
    }

    input[type="checkbox"] {
        background-color: red;
        display: inline-block;
    }

    .remark {
        margin: 0;
        color: $red;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .vdp-datepicker__calendar {
        z-index: 10 !important;
        right: 0;
    }

    .cell.selected {
        background-color: $blue !important;
        color: $white !important;
    }
}
</style>
