<template>
    <div class="add-project content">
        <main>
            <section class="header" id="add-project">
                <h2 class="title text-right">
                    {{ $t("portfolio.add.catch") }}
                </h2>
                <p class="text-right" v-html="$t('portfolio.add.text')"></p>
                <div class="container back-link">
                    <Link
                        :back="true"
                        path="/portfolio"
                        :title="$t('nav.return')"
                    />
                </div>
                <div class="add-project">
                    <AddProjectForm
                        @success="redirect"
                        @failed="$emit('done')"
                        @submit="loading"
                        :category="category"
                    />
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import AddProjectForm from "@/components/form/AddProjectForm";
import Link from "@/components/Link";

export default {
    components: {
        AddProjectForm,
        Link
    },
    props: {
        category: String
    },
    data: function() {
        return {};
    },
    methods: {
        loading() {
            this.$emit("loading", this.$t("portfolio.add.loading"));
        },
        redirect() {
            this.$emit("done");
            this.$router.push("/portfolio");
        }
    },
    metaInfo() {
        return {
            title: `${
                this.$root.$i18n.locale === "en" ? "Add project" : "Project toevoegen"
            }`,
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Add project - Renders by Julie"
                },
                {
                    name: "description",
                    content: "Toevoegen van een project aan portfolio"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "robots",
                    content: "noindex, nofollow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                }
            ]
        };
    }
};
</script>
<style lang="scss">
#add-project {
    @media (max-width: 700px) {
        * {
            text-align: left;
        }
    }
}
</style>
